import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import cx from "classnames"
import loadable from "@loadable/component"
import ScrollReveal from "scrollreveal"
import CountUp from "react-countup"

import { IMG } from "../const"
import Layout from "../components/layout"
import "../assets/styles/commons/_common-kit.scss"
import "../assets/styles/commons/_customers-logo__section.scss"
import * as styles from "../assets/styles/home.module.scss"
import {
  CUSTOMERS,
  TITLES,
  ANIMATED_COUNTERS,
  SERVICES_LIST,
  TESTIMONIALS,
} from "../data/home-page"
import Headline from "../components/headline"
import TitleBlock from "../components/title-block"
import Testimonials from "../components/testimonials"
import ServiceCard from "../components/carousels/ServiceCard"
import ServicesCarousel from "../components/carousels/ServicesCarousel"

const ContactForm = loadable(() => import("../components/contact-form"))

const IndexPage = () => {
  const counterBlock = useRef(null)
  const [startCount, setStartCount] = useState(false)

  // Start counters on scroll
  useEffect(() => {
    const handleStartCount = e => {
      const counterBlockOffset = counterBlock.current.getBoundingClientRect()
      const counterBlockPosition = counterBlockOffset.top - 600
      if (!startCount && counterBlockPosition < 0) {
        setStartCount(true)
      }
    }

    document.addEventListener("scroll", handleStartCount, { passive: true })

    return () => {
      document.removeEventListener("scroll", handleStartCount)
    }
  }, [startCount])

  // Start counters on view
  useEffect(() => {
    if (window.innerHeight > 650) {
      setStartCount(true)
    }
  }, [])

  useEffect(() => {
    ScrollReveal().reveal(`#Customers li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 50,
      delay: 50,
    })
  }, [])

  useEffect(() => {
    // Scroll to anchor
    let pathHash = window.location.hash

    if (pathHash) {
      let hash = pathHash.replace("#", "")

      setTimeout(() => {
        const el = document.getElementById(`${hash}`)
        if (el) {
          el.scrollIntoView({
            behavior: "smooth",
          })
        }
      }, 0)
    }
  })

  return (
    <Layout
      pageTitle="Web & Mobile Development Agency"
      metaDescription="LaSoft: Your technology partner. Innovative solutions tailored to your needs. Elevate your business with LaSoft. Explore now!"
    >
      {({
        width,
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <>
            <main className={cx(styles.homeBlock, "common-kit")}>
              <article>
                <Headline>
                  <div className={cx(styles.headline, "headline")}>
                    <div className={cx(styles.headline_grid, "headline_grid")}>
                      <p className={"strongText"}>We’re a</p>
                      <h1 className={"withIndent"}>
                        <strong>Software development</strong> company you can
                        trust
                      </h1>
                      <div className={"rightColumn"}>
                        <p>
                          We Design, Build, and Deliver Web and Mobile
                          applications worldwide
                        </p>

                        <div>
                          <Link
                            to="#contact-form"
                            className="goldFatLink home-contact"
                          >
                            Contact us
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Headline>
                <section className={cx(styles.infoBlock, "section")}>
                  <div>
                    <ul className={styles.countersBlock} ref={counterBlock}>
                      {ANIMATED_COUNTERS.map(item => (
                        <li key={item.id}>
                          <strong>
                            {startCount && (
                              <CountUp
                                start={item.start}
                                end={item.end}
                                delay={item.delay}
                                duration={7}
                                useEasing={false}
                                suffix={item.suffix ? item.suffix : ""}
                              >
                                {({ countUpRef }) => <ins ref={countUpRef} />}
                              </CountUp>
                            )}
                          </strong>
                          <span>{item.text}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <picture>
                      <source
                        media="(max-width: 480px)"
                        srcSet={`${IMG.HOME}/img/office.avif,		${IMG.HOME}/img/office@2x.avif 2x,		${IMG.HOME}/img/office@3x.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(max-width: 480px)"
                        srcSet={`${IMG.HOME}/img/office.webp,		${IMG.HOME}/img/office@2x.webp 2x,		${IMG.HOME}/img/office@3x.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(max-width: 480px)"
                        srcSet={`${IMG.HOME}/img/office.jpg,		${IMG.HOME}/img/office@2x.jpg 2x,		${IMG.HOME}/img/office@3x.jpg 3x`}
                      />

                      <source
                        media="(min-width: 481px)"
                        srcSet={`${IMG.HOME}/img/office-tablet.avif,		${IMG.HOME}/img/office-tablet@2x.avif 2x,		${IMG.HOME}/img/office-tablet@3x.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 481px)"
                        srcSet={`${IMG.HOME}/img/office-tablet.webp,		${IMG.HOME}/img/office-tablet@2x.webp 2x,		${IMG.HOME}/img/office-tablet@3x.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width: 481px)"
                        srcSet={`${IMG.HOME}/img/office-tablet.jpg,		${IMG.HOME}/img/office-tablet@2x.jpg 2x,		${IMG.HOME}/img/office-tablet@3x.jpg 3x`}
                      />

                      <source
                        media="(min-width: 769px)"
                        srcSet={`${IMG.HOME}/img/office.avif,		${IMG.HOME}/img/office@2x.avif 2x,		${IMG.HOME}/img/office@3x.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 769px)"
                        srcSet={`${IMG.HOME}/img/office.webp,		${IMG.HOME}/img/office@2x.webp 2x,		${IMG.HOME}/img/office@3x.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width: 769px)"
                        srcSet={`${IMG.HOME}/img/office.jpg,		${IMG.HOME}/img/office@2x.jpg 2x,		${IMG.HOME}/img/office@3x.jpg 3x`}
                      />
                      <img
                        alt="Our office"
                        src={`${IMG.HOME}/img/office@2x.jpg`}
                        width="393"
                        height="415"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div>
                    <picture>
                      <source
                        media="(max-width: 480px)"
                        srcSet={`${IMG.HOME}/img/meeting-mobile.avif,		${IMG.HOME}/img/meeting-mobile@2x.avif 2x,		${IMG.HOME}/img/meeting-mobile@3x.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(max-width: 480px)"
                        srcSet={`${IMG.HOME}/img/meeting-mobile.webp,		${IMG.HOME}/img/meeting-mobile@2x.webp 2x,		${IMG.HOME}/img/meeting-mobile@3x.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(max-width: 480px)"
                        srcSet={`${IMG.HOME}/img/meeting-mobile.jpg,		${IMG.HOME}/img/meeting-mobile@2x.jpg 2x,		${IMG.HOME}/img/meeting-mobile@3x.jpg 3x`}
                      />

                      <source
                        media="(min-width: 481px)"
                        srcSet={`${IMG.HOME}/img/meeting-tablet.avif,		${IMG.HOME}/img/meeting-tablet@2x.avif 2x,		${IMG.HOME}/img/meeting-tablet@3x.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 481px)"
                        srcSet={`${IMG.HOME}/img/meeting-tablet.webp,		${IMG.HOME}/img/meeting-tablet@2x.webp 2x,		${IMG.HOME}/img/meeting-tablet@3x.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width: 481px)"
                        srcSet={`${IMG.HOME}/img/meeting-tablet.jpg,		${IMG.HOME}/img/meeting-tablet@2x.jpg 2x,		${IMG.HOME}/img/meeting-tablet@3x.jpg 3x`}
                      />

                      <source
                        media="(min-width: 769px)"
                        srcSet={`${IMG.HOME}/img/meeting.avif,		${IMG.HOME}/img/meeting@2x.avif 2x,		${IMG.HOME}/img/meeting@3x.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 769px)"
                        srcSet={`${IMG.HOME}/img/meeting.webp,		${IMG.HOME}/img/meeting@2x.webp 2x,		${IMG.HOME}/img/meeting@3x.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width: 769px)"
                        srcSet={`${IMG.HOME}/img/meeting.jpg,		${IMG.HOME}/img/meeting@2x.jpg 2x,		${IMG.HOME}/img/meeting@3x.jpg 3x`}
                      />
                      <img
                        alt="Meeting"
                        src={`${IMG.HOME}/img/meeting@2x.jpg`}
                        loading="lazy"
                        width="598"
                        height="331"
                      />
                    </picture>
                  </div>
                </section>

                <section className={cx("section", styles.banner)}>
                  <div>
                    <div className={styles.banner__img}>
                      <picture>
                        <source
                          media="(max-width: 600px)"
                          srcSet={`${IMG.HOME}/bunner/banner-mobile.avif,		${IMG.HOME}/bunner/banner-mobile@2x.avif 2x,		${IMG.HOME}/bunner/banner-mobile@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(max-width: 600px)"
                          srcSet={`${IMG.HOME}/bunner/banner-mobile.webp,		${IMG.HOME}/bunner/banner-mobile@2x.webp 2x,		${IMG.HOME}/bunner/banner-mobile@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(max-width: 600px)"
                          srcSet={`${IMG.HOME}/bunner/banner-mobile.jpg,		${IMG.HOME}/bunner/banner-mobile@2x.jpg 2x,		${IMG.HOME}/bunner/banner-mobile@3x.jpg 3x`}
                        />

                        <source
                          media="(min-width: 601px) and (max-width: 768px)"
                          srcSet={`${IMG.HOME}/bunner/banner-tablet.avif,		${IMG.HOME}/bunner/banner-tablet@2x.avif 2x,		${IMG.HOME}/bunner/banner-tablet@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(min-width: 601px) and (max-width: 768px)"
                          srcSet={`${IMG.HOME}/bunner/banner-tablet.webp,		${IMG.HOME}/bunner/banner-tablet@2x.webp 2x,		${IMG.HOME}/bunner/banner-tablet@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(min-width: 601px) and (max-width: 768px)"
                          srcSet={`${IMG.HOME}/bunner/banner-tablet.jpg,		${IMG.HOME}/bunner/banner-tablet@2x.jpg 2x,		${IMG.HOME}/bunner/banner-tablet@3x.jpg 3x`}
                        />

                        <source
                          media="(min-width: 769px) and (max-width: 1024px)"
                          srcSet={`${IMG.HOME}/bunner/banner-tablet-w.avif,		${IMG.HOME}/bunner/banner-tablet-w@2x.avif 2x,		${IMG.HOME}/bunner/banner-tablet-w@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(min-width: 769px) and (max-width: 1024px)"
                          srcSet={`${IMG.HOME}/bunner/banner-tablet-w.webp,		${IMG.HOME}/bunner/banner-tablet-w@2x.webp 2x,		${IMG.HOME}/bunner/banner-tablet-w@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(min-width: 769px) and (max-width: 1024px)"
                          srcSet={`${IMG.HOME}/bunner/banner-tablet-w.jpg,		${IMG.HOME}/bunner/banner-tablet-w@2x.jpg 2x,		${IMG.HOME}/bunner/banner-tablet-w@3x.jpg 3x`}
                        />

                        <source
                          media="(min-width: 1025px)"
                          srcSet={`${IMG.HOME}/bunner/banner-desktop.avif,		${IMG.HOME}/bunner/banner-desktop@2x.avif 2x,		${IMG.HOME}/bunner/banner-desktop@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(min-width: 1025px)"
                          srcSet={`${IMG.HOME}/bunner/banner-desktop.webp,		${IMG.HOME}/bunner/banner-desktop@2x.webp 2x,		${IMG.HOME}/bunner/banner-desktop@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(min-width: 1025px)"
                          srcSet={`${IMG.HOME}/bunner/banner-desktop.jpg,		${IMG.HOME}/bunner/banner-desktop@2x.jpg 2x,		${IMG.HOME}/bunner/banner-desktop@3x.jpg 3x`}
                        />

                        <img
                          alt="Start smart with MVP"
                          src={`${IMG.HOME}/bunner/banner-desktop.jpg`}
                          loading="lazy"
                          width="1210"
                          height="560"
                        />
                      </picture>
                    </div>
                    <div className={styles.banner__text}>
                      <h2>dream big and start smart with MVP</h2>
                      <p>
                        A Minimum Viable Product incorporates only the essential
                        functionalities for developing a feasible customer
                        solution. For this reason, it is the smartest approach
                        to validate a business idea and create the foundation
                        for a sustainable digital&nbsp;product.
                      </p>
                      <div className={styles.banner__links}>
                        <Link
                          to="#contact-form"
                          className="goldFatLink home-estimate"
                        >
                          Estimate MVP
                        </Link>
                        <Link to="/mvp-development/" className={styles.link}>
                          Learn More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className={styles.banner__mobileLinks}>
                    <Link
                      to="#contact-form"
                      className="goldFatLink home-estimate"
                    >
                      Estimate MVP
                    </Link>
                    <Link to="/mvp-development/" className={styles.link}>
                      Learn More
                    </Link>
                  </div>
                </section>

                <section className={cx("section", styles.services)}>
                  <TitleBlock options={TITLES.services} />
                  {width <= 650 ? (
                    <div className={styles.services__carouselWrapper__mobile}>
                      {SERVICES_LIST.map(item => (
                        <ServiceCard key={item.id} item={item} />
                      ))}
                    </div>
                  ) : (
                    <div className={styles.services__carouselWrapper__desktop}>
                      <ServicesCarousel list={SERVICES_LIST} />
                    </div>
                  )}
                </section>

                <section className="section customers-logo__section">
                  <ul className="customers-list" id="Customers">
                    {CUSTOMERS.map(customer => (
                      <li key={customer.name}>
                        <img
                          src={`${IMG.CUSTOMERS_LOGO}/grey/${customer.image}.svg`}
                          alt={customer.name}
                          width="180"
                          height="80"
                          loading="lazy"
                        />
                      </li>
                    ))}
                  </ul>
                </section>

                <div className={styles.testimonials} id="Testimonials">
                  <Testimonials
                    title={TITLES.testimonials}
                    testimonials={TESTIMONIALS}
                    screenWidth={width}
                  />
                </div>
              </article>
            </main>
            <section className="footerForm common-kit">
              <div className="section" id="contact-form">
                <ContactForm
                  formStatID="CommonFooter"
                  handleOpenConfirmation={handleOpenConfirmation}
                  isOpenConfirmation={isOpenConfirmation}
                  setConfirmationOption={setConfirmationOption}
                />
              </div>
            </section>
          </>
        )
      }}
    </Layout>
  )
}

export default IndexPage
